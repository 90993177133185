<template>
    <!-- <Dialog :header="Title" v-model:visible="displayEditCardDialog" class="editCardDialog" style="width: 40vw;" :modal=true :dismissableMask=true > -->
    <Dialog :showHeader=false v-model:visible="displayEditCardDialog" style="width: 40vw;height: 100%;" class="editBoardCardDialog" contentClass="editCardDialogContent" :modal=true :dismissableMask="!isShowCover && !(isSubjectEditing || isContentEditing)" :contextmenuNone=true >
        <Card style="border: none; box-shadow: none;" @dragover.prevent="onDrag('over')"  @drop.prevent="onDrop" >
            <template #header v-if="CoverImageUrl">
                <template v-if="$constants.imageExtList.includes(CoverFileNameExt)">
                    <div class="cursor_pointer coverImage" v-bind:style="{'backgroundImage': coverImageBackgroundURL}" @click="onCoverImageClick($event, CoverImageUrl)" />
                </template>
                <template v-else>
                    <a class="coverImage" >
                        <span class="attachmentThumbPreviewExtHeader">{{CoverFileName}}</span>
                    </a>
                </template>

                <template v-if="isUploading">
                    <div class="progressSpinnerContainer">
                        <ProgressSpinner  />
                    </div>
                </template>
                <div class="" style="width: 40px; position: absolute; top: 0px; right: 0px;">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain p-mr-2"  @click="onCloseButtonClick" style="font-size: 0.6rem; z-index: 2" />
                </div>
            </template>
            <template #title>
                <div class="p-col-12 p-field p-grid no_margin">
                <div class="p-col p-grid no_margin no_padding editTopCardInplace">
                    <div class="p-col-12 p-grid no_margin no_padding">
                        <Textarea 
                             style="width: 100%;" 
                             v-model="Title" 
                             :autoResize="true"
                             class=""
                             rows="1" 
                             ref="textarea_title_ref" 
                             @blur="blurEditTitleTextarea"
                             @focus="onFocusEditTitleTextarea"
                             @keydown.enter.ctrl.exact="enterTitleEditCard"
                             @keydown.enter.meta.exact="enterTitleEditCard"
                             :class="{'p-invalid': invalidTitleTextArea.value}"
                         />
                    </div>
                    <div class="p-col-12 p-grid p-nogutter  no_padding p-ml-0 p-mt-2" >
                        <Button :disabled="!isSubjectEditing" @click="saveEditTitleTextarea" class="createNewButton" style="line-height: 12px;" > <div style="display: contents; text-transform:capitalize;">{{$t("utils.save")}} </div> <br /> <div style="font-size: 10px;display: contents;">(Ctrl+Enter)</div></Button>
                        <Button :disabled="!isSubjectEditing" @click="cancelEditTitleTextarea" icon="pi pi-times" class="p-button-rounded p-button-text  p-button-plain" />
                        <div class="p-col" />
                        <div style="font-size: 10px;">{{$t("utils.save_ctrl")}}</div>
                    </div>
                </div>
                <div class="p-col-fixed" style="width: 40px;" v-if="!CoverImageUrl">
                    <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain p-mr-2"  @click="onCloseButtonClick" style="font-size: 0.6rem; z-index: 2" />
                </div>
                </div>
            </template>
            <template #content>
                <div class="p-grid no_margin">
                    <div class="p-col-12 CardDialogContent p-field p-grid no_margin">
                        <TagLineField :BoardId="BoardId" :TagIdList="TagIdList" :BoardCardId="BoardCardId"  />
                        <div class="p-col-12 no_padding text-right" style="">
                            <i class="p-pl-2 pi pi-tag cursor_pointer" @click="onPopTagIconClick"></i>
                            <OverlayPanel ref="popTagPanelRef" style="min-width: 300px;" >
                                <TagPopPanel :BoardId="BoardId" :BoardCardId="BoardCardId" @closeTagPopPanel="closeTagPopPanel" />
                            </OverlayPanel>

                            <i class="p-pl-2 pi pi-sort-alt cursor_pointer"  @click="onPopMoveIconClick" :disabled="IsPinned" ></i>
                            <OverlayPanel ref="popMovePanelRef" style="width: 300px; min-width: 300px;" >
                                <CardMovePopPanel :BoardId="BoardId" :BoardListId="BoardListId" :BoardCardId="BoardCardId" />
                            </OverlayPanel>

                            <i class="p-pl-2 pi pi-trash cursor_pointer"  @click="onDeleteCardClick" ></i>

                            <Button @click="setPinnedBoardCard" class="p-button-outlined p-button-info" icon="" v-if="IsPinned" style="border-color: white">  
                                <i class="" style="" v-tooltip="$t('EditCardDialog.clearPinnedCard')" >
                                    <ThumbtackIcon class=" fa-rotate-90" />
                                    <BanIcon style="position: absolute; top: 0px; left: 0px; " class="warning-color" /> 
                                </i>
                            </Button>
                            <Button @click="setPinnedBoardCard" class="p-button-outlined p-button-info" icon="" style="border-color: white" v-else>  
                                <i class="" style=""  v-tooltip="$t('EditCardDialog.pinnedCard')" >
                                    <ThumbtackIcon class=" fa-rotate-45" />
                                </i>
                            </Button>
                        </div>
                        <b>{{$t('top.card.Description')}}</b>
                        <br />
                        <!-- <div style="color: red;" v-if="notSavedContent">{{$t('top.card.isNotSaved')}}</div> -->
                        <div class="p-col-12 p-grid no_margin no_padding ">
                            <div class="p-col-12 p-grid no_margin no_padding">
                                <Inplace class="handle editBoardListTitleInplace width-100 " :active="activeInPlace" @open="openInplace" style=" min-height: 120px;">
                                    <template #display>
                                        {{Content}}
                                    </template>
                                    <template #content >
                                        <Textarea 
                                         style="width: 100%;padding: 7px;"
                                         v-model="Content" 
                                         class=""
                                         rows="2" 
                                         @focus="focusEditContentTextarea"
                                         ref="textarea_ref" 
                                         @blur="blurEditContentTextarea"
                                         @keydown.enter.ctrl.exact="enterContentEditCard"
                                         @keydown.enter.meta.exact="enterContentEditCard"
                                         @keydown.ctrl.v.exact="getImageDataFromClipboard"
                                         @paste="getImageDataFromClipboard"
                                         :class="{'p-invalid': invalidTextArea.value}"
                                         autoResize
                                         />
                                         <div style="width: 100%; height: 10px;" />
                                    </template >
                                </Inplace>
                                        <div class="p-col-12 p-grid no_margin_side no_padding p-mt-1" style="justify-content: flex-start;">
                                            <Button :disabled="!isContentEditing" class="createNewButton activeCreateNewCardButton p-mr-2" @click="onUpdateCardButtonClick"  style="line-height: 12px;" > <div style="display: contents; text-transform:capitalize;">{{$t("utils.save")}} </div> <br /> <div style="font-size: 10px;display: contents;">(Ctrl+Enter)</div></Button>
                                            <Button :disabled="!isContentEditing" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" @click="onCancelUpdateButtonClick" style="font-size: 0.6rem" />
                                                <div class="p-col"></div>
                                                <div style="font-size: 10px;">{{$t("utils.save_ctrl")}}</div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 60px;" />
                <b class="p-col-12">{{$t('top.card.AddAttachment')}}</b>
                <div class="p-col-12" style="padding-top: 0px;">
                    <Button class="createNewButton" @click="onAddAttachmentButtonClick($event)" >{{$t('top.card.addAttachment')}}</Button>
                    <input id='addCardFileButton' @change="changeUploadFile" type='file' hidden/>
                    <template v-if="isUploading">
                        <div class="attachmentThumb p-mt-2">
                            <a class="attachmentThumbPreview" >
                                <Skeleton style="height: 100%;" class="attachmentThumbPreviewExt"></Skeleton>
                            </a>
                            <p class="attachmentThumbDetail">
                                <span>
                                    <Skeleton class="" borderRadius="16px"></Skeleton>
                                </span>
                                <span class="" style="display: inline-flex;">
                                    <Skeleton width="50px" class="p-mt-1 p-mr-1" borderRadius="16px"></Skeleton>
                                    <Skeleton width="50px" class="p-mt-1 p-mr-1" borderRadius="16px"></Skeleton>
                                    <Skeleton width="50px" class="p-mt-1" borderRadius="16px"></Skeleton>
                                </span>
                            </p>
                        </div>
                    </template>
                    <template v-for="Attachment in AttachmentList" :key="Attachment._id">
                        <div class="attachmentThumb">
                            <template v-if="$constants.imageExtList.includes(Attachment.Ext)">
                                <a 
                                      class="attachmentThumbPreview" 
                                      v-bind:style="{'backgroundImage': previewImageBackgroundURL(Attachment)}" 
                                      @click="onAttachmentThumbDetailClick($event, Attachment)">
                                    {{previewImage(Attachment)}}
                                </a>
                            </template>
                            <template v-else>
                                <a class="attachmentThumbPreview" >
                                    <span class="attachmentThumbPreviewExt">{{Attachment.Ext}}</span>
                                </a>
                            </template>
                            <p class="attachmentThumbDetail" @click="onAttachmentThumbDetailClick($event, Attachment)">
                                <span class="attachmentThumbDetailFileName">{{Attachment.FileName}}</span>
                                <OverlayPanel :ref="'changeFileNameCommand_'+Attachment._id">
                                    <div class="p-col-12 p-field p-grid no_margin" style="width: 380px;">
                                        <div class="p-col-12 no_padding p-inputgroup">
                                            <InputText aria-describedby="EdittingFileName-help" type="text" v-model="Attachment.EdittingFileName" :class="{'p-invalid': invalidViewMode.value}" />
                                            <span class="p-inputgroup-addon">.{{Attachment.Ext}}</span>
                                        </div>
                                        <small style="white-space: pre-line" id="EdittingFileName-help" :class="{'p-error': invalidViewMode.value}">{{invalidViewMode.text}}</small>

                                        <div class="p-col-12 no_padding p-grid no_margin">
                                            <Button class="p-button-outlined p-button-secondary p-mt-2" @click="onCloseFileNameAttachmentClick($event, Attachment)" >{{$t('utils.cancel')}}</Button>
                                            <div class="p-col" />
                                            <div class=" p-mt-2" style="justify-content: end;">
                                                <Button class="" @click="onChangeFileNameAttachmentClick($event, Attachment)" >{{$t('utils.save')}}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </OverlayPanel>
                                <i class="p-pl-2 downloadAttachmentIcon pi pi-download" 
                                    @click.stop="onAttachmentDownloadIconlick($event, Attachment, Attachment.URL, Attachment.OriginalFileName, Attachment._id, Attachment.ContentType)"
                                   :disabled="Attachment.disabled"
                                   ></i>
                                <br/>
                                <span class="attachmentThumbDetailFileDate">{{$filters.formatDate(Attachment.CreatedDate, null, "HH:mm:ss")}}</span> - 
                                <span class="attachmentThumbDetailFileCommand" @click.stop="onAttachmentCommentCommandClick($event, Attachment)" >{{$t('top.card.commentAttachment')}}</span> -
                                <span class="attachmentThumbDetailFileCommand" @click.stop="onAttachmentChangeFilenameCommandClick($event, Attachment)" >{{$t('top.card.changeFileNameAttachment')}}</span> -
                                <span class="attachmentThumbDetailFileCommand" @click.stop="onAttachmentDeleteCommandClick($event, Attachment._id)" >{{$t('top.card.deleteAttachment')}}</span>
                                <OverlayPanel :ref="'deleteFileCommand_'+Attachment._id" >
                                    <div class="p-col-12 p-field p-grid no_margin">
                                        <div class="p-col-12" style="white-space: pre-wrap;">
                                            {{$t('top.card.deleteAttachmentText')}}
                                        </div>
                                        <Button class="p-button-danger" @click="onAttachmentDeleteClick($event, Attachment._id)" >{{$t('top.card.deleteAttachment')}}</Button>
                                    </div>
                                </OverlayPanel>
                                <br />
                                <span v-if="!Attachment.IsCoveredImage" class="attachmentThumbDetailFileCommand" @click.stop="onAttachmentMakeCoverClick($event, Attachment._id)" >{{$t('top.card.setHeader')}}</span>
                                <span v-else class="attachmentThumbDetailFileCommand" @click.stop="onAttachmentRemoveCoverClick($event, Attachment._id)" >{{$t('top.card.removeHeader')}}</span> 

                            </p>
                        </div>
                        <template v-if="Attachment.Comment || Attachment.CommentEditting">
                            <div class="p-col-12 p-field p-grid no_margin no_padding" style="">
                                <div class="p-col-fixed" style="width: 112px; height: 1px;" />
                                <Inplace class="handle editBoardListTitleInplace p-col" :active="Attachment.activeCommentInPlace" @open="openCommentInplace(Attachment, Attachment._id)" style=" min-height: 40px; word-break: break-word; white-space: pre-line;">
                                    <template #display>
                                        {{Attachment.Comment}}
                                    </template>
                                    <template #content>
                                        <Textarea 
                                         style="width: 100%;padding: 7px;" 
                                         v-model="Attachment.Comment" 
                                         :autoResize="true"
                                         class="isBorderNone"
                                         :ref="'textarea_comment_ref_'+Attachment._id"
                                         @keydown.enter.meta.exact="onAttachmentCommentTextareaBlur($event, Attachment)"
                                         @keydown.enter.ctrl.exact="onAttachmentCommentTextareaBlur($event, Attachment)"
                                         @focus="onAttachmentCommentTextareaFocus($event, Attachment)"
                                         @blur="onAttachmentCommentTextareaBlur($event, Attachment)"
                                         rows="2" 
                                         />
                                    </template >
                                </Inplace>
                            </div>
                        </template>
                        <div style="height :8px; width: 100%;" />
                    </template>
                </div>
                <EditorImagePopup :imgs="PopupImageUrl" index="0" :visibleProps="isShowCover" @hide="isShowCover=false" />
            </template>
        </Card>
        <template #footer style="padding-bottom: 0px;">
            <!-- <Button icon="pi pi-trash" style="" class="p-button-rounded p-button-danger p-button-text" v-tooltip="$t('top.card.deleteAttachment')" @click="onTrashCardButtonClick"/> -->
            <div class="p-col-12 p-grid">
                <div >
                    <span class="cardFromDetailInfo">{{No}}</span> - 
                    <span class="cardFromDetailInfo">{{ListName}}</span> - 
                    <span class="cardFromDetailInfo">{{BelongToTitle}}</span> 
                </div>
                <div class="p-col" />
            </div>
        </template>
        <div v-if="isDragOver" class="droppingZone" @dragover.prevent="onDropzoneEnter" @dragleave.prevent="onDrag('leave')"  @drop.prevent="onDrop"> drop </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Inplace from 'primevue/inplace';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button'
import ThumbtackIcon from "@/components/Icons/thumbtackIcon"
import BanIcon from "@/components/Icons/banIcon"
import Tooltip from 'primevue/tooltip';
import Card from 'primevue/card';
import EditorImagePopup from "@/components/EditorImagePopup";
import ProgressSpinner from 'primevue/progressspinner';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import http from '@/mixins/api'
import TagLineField from "@/components/board/TagLineField"
import TagPopPanel from "@/components/board/TagPopPanel"
import CardMovePopPanel from "@/components/board/CardMovePopPanel"
import Skeleton from 'primevue/skeleton';

import helper from "@/mixins/helper";



export default {
    name: 'EditCardDialog',
    props: ['displayEditCardDialog', 'BoardId', 'BoardListId', 'BoardCardId'],
    emits: ['updateDisplayEditCardDialog'],
    components: {
        Dialog,
        Button,
        Inplace,
        Textarea,
        ThumbtackIcon,
        BanIcon,
        Card,
        EditorImagePopup,
        ProgressSpinner,
        OverlayPanel,
        InputText,
        TagLineField,
        TagPopPanel,
        CardMovePopPanel,
        Skeleton,
    },
    data: function() {
        return {
            editTitleValue: "",
            editContentValue: "",
            activeInPlace: false,
            can_enter: false,
            isContentEditing: false,
            initContent: "",
            // notSavedContent: false,
            isShowCover: false,
            isUploading: false,
            PopupImageUrl: "",
            invalidViewMode: {
                value: false,
                text: this.$i18n.t("EditCardDialog.invalidFileNameLetter", {INVALID: this.$constants.invalidFileNameLetter})
            },
            isDragOver: false,
            isSubjectEditing: false,
            invalidTextArea: {
                value: false,
            },
            invalidTitleTextArea: {
                value: false,
            },
        }
    },
    watch: {
        displayEditCardDialog: function(value) {
            this.$emit("updateDisplayEditCardDialog", value)
            // this.notSavedContent = false
            this.isContentEditing = false
            this.invalidTextArea = {
                value: false,
            }
            this.invalidTitleTextArea = {
                value: false,
            }
            if(value) {
                const self = this;
                this.$nextTick(function() {
                    self.initContent = self.Content
                })
            }
        }
    },
    created() {
    },
    computed: {
        Title: {
            get: function() {
                return this.$store.getters["activeBoardCard/getTitle"]
                // return `${this.$i18n.tc('EditCardDialog.reload')} ${this.$filters.formatDate(this.UpdatedDate, null, 'HH:mm:ss')}`
            },
            set: function(value) {
                this.$store.commit("activeBoardCard/setTitle", value)
            }
        },
        Content: {
            get: function() {
                return this.$store.getters["activeBoardCard/getContent"]
            },
            set: function(value) {
                this.$store.commit("activeBoardCard/setContent", value)
            }
        },
        CoverFileNameExt: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverFileNameExt"]
            },
        },
        CoverFileName: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverFileName"]
            },
        },
        CoverImageUrl: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverImageUrl"]
            },
        },
        coverImageBackgroundURL: {
            get: function() {
                return `url(${this.CoverImageUrl})`
            },
        },
        UpdatedDate: {
            get: function() {
                return this.$store.getters["activeBoardCard/getUpdatedDate"]
            },
        },
        IsPinned: {
            get: function() {
                return this.$store.getters["activeBoardCard/getPinned"]
            },
        },
        AttachmentList: {
            get: function() {
                return this.$store.getters["activeBoardCard/getAttachmentList"]
            }
        },
        No: {
            get: function() {
                return this.$store.getters["activeBoardCard/getNo"]
            }
        },
        BelongToTitle: {
            get: function() {
                return this.$store.getters["activeBoardCard/getBelongToTitle"]
            }
        },
        ListName: {
            get: function() {
                return this.$store.getters["activeBoardCard/getListName"]
            }
        },
        TagIdList: {
            get: function() {
                return this.$store.getters["activeBoardCard/getTagIdList"];
            }
        },
    },
    methods: {
        async UpdateCardTitle() {
            if(!(await this.checkCardVersion()))  {
                this.cancelEditTitleTextarea()
                return
            }
            this.isSubjectEditing = false
            const res = await this.$store.dispatch("activeBoardCard/updateCardTitle")
            if(res.Success) {
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),
                    life: this.$constants.successHelperLife,
                    group: this.$constants.defaultToastGroup
                });
            }
        },

        async checkCardVersion() {
            const self = this
            return new Promise(async function(resolve, reject) {
                const res = await self.$store.dispatch("activeBoardCard/checkCardVersion")
                if(res.Success) {
                    resolve(true)
                } else {
                    self.$confirm.require({
                        message: self.$i18n.tc("top.anotherUserModified", {USEREMAIL: res.LastUpdatedWorkUserEmail}),
                        accept: function() {
                            resolve(true)
                        },
                        reject: () => {
                            resolve(false)
                        },
                    })
                }
            })

        },

        async UpsertNewCard() {
            if(!(await this.checkCardVersion()))  {
                this.onCancelUpdateButtonClick()
                return
            }
            this.isContentEditing = false
            this.activeInPlace = false
            const res = await this.$store.dispatch("activeBoardCard/upsertNewCard")
            if(res.Success) {
                this.initContent = this.Content
                this.$store.commit("activeBoardCard/setUpdatedDate", res.Result.UpdatedDate)
                this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),
                    life: this.$constants.successHelperLife,
                    group: this.$constants.defaultToastGroup
                });
            }
        },

        async onUpdateCardButtonClick() {
            if (!this.checkInvalidContent()) {
                // this.notSavedContent = false
                await this.UpsertNewCard()
            }
        },

        onCancelUpdateButtonClick() {
            this.isContentEditing = false
            this.activeInPlace = false
            // this.notSavedContent = false
            this.$store.commit("activeBoardCard/setContent", this.initContent)
        },

        async focusEditContentTextarea() {
            this.isContentEditing = true
        },

        enterTitleEditCard() {
            this.$refs.textarea_title_ref.$el.blur()
        },

        onFocusEditTitleTextarea() {
            this.isSubjectEditing  = true
        },

        async blurEditTitleTextarea() {
            const self = this
            if (!this.checkInvalidTitle()) {
                setTimeout(async function() {
                    if (self.isSubjectEditing) {
                        await self.UpdateCardTitle()
                    }
                }, 300)
            }
        },

        async saveEditTitleTextarea() {
            this.isSubjectEditing = false
            await this.UpdateCardTitle()
        },

        cancelEditTitleTextarea() {
            this.$store.commit("activeBoardCard/setTitle", this.$store.getters["activeBoardCard/getOriginalTitle"])
            this.isSubjectEditing = false
        },

        enterContentEditCard() {
            this.$refs.textarea_ref.$el.blur()
        },

        checkInvalidTitle() {
            const invalid = this.Title.length >= this.$constants.maxCardTitleLength
            if (invalid) {
                this.invalidTitleTextArea.value = true
                this.$toast.add({severity: 'error', summary: this.$i18n.t("EditCardDialog.invalidTitleLength"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.invalidTitleTextArea.value = false
            }
            return invalid
        },

        checkInvalidContent() {
            const invalid = this.Content.length >= this.$constants.maxCardContentLength
            if (invalid) {
                this.invalidTextArea.value = true
                this.$toast.add({severity: 'error', summary: this.$i18n.t("EditCardDialog.invalidContentLength"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.invalidTextArea.value = false
            }
            return invalid
        },

        async blurEditContentTextarea() {
            if (!this.checkInvalidContent()) {
                const self = this
                setTimeout(async function() {
                    if(self.isContentEditing) {
                        // self.notSavedContent = true
                        self.onUpdateCardButtonClick()
                    }
                }, 300)
            }
        },

        trashBoardCard: function() {
            const self = this;
            this.$confirm.require({
                message: self.$i18n.tc("top.deleteCard"),
                accept: async function() {
                    const res = await self.$store.dispatch("activeBoardCard/trashBoardCard", self.BoardCardId)
                    if(res.Success) {
                        self.$toast.add({severity:'success', summary: self.$i18n.tc("utils.deleteSuccess"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        await self.$store.dispatch("trello/getBoardListCards", {BoardListId: self.BoardListId, BoardId: self.BoardId })
                        self.$emit("updateDisplayEditCardDialog", false)
                    } else {
                        self.$toast.add({severity:'error', summary: self.$i18n.tc("utils.failed"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                    }
                },
                reject: () => {
                },
            })
        },

        setPinnedBoardCard: async function() {
            const nextPinned = !this.IsPinned
            await this.$store.dispatch("activeBoardCard/setPinnedCard", {BoardCardId: this.BoardCardId, IsPinned: nextPinned })
            this.$store.commit("activeBoardCard/setIsPinned", nextPinned)

            await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })
        },

        async onTrashCardButtonClick() {
            this.trashBoardCard()
        },

        onEditCardKeyDown() {
            this.$refs.textarea_ref.$el.blur()
        },

        enable_enter: function(e) {
            this.can_enter = true;
        },

        enter: function(e) {
            if (!this.can_enter) return;
            this.onEditCardKeyDown()
            this.can_enter = false;

        },

        openInplace() {
            this.activeInPlace = true
            const self = this;
            this.$nextTick(function() {
                // self.$refs.textarea_ref.$el.focus()
                self.$refs.textarea_ref.$el.select()
            })
        },

        onCloseButtonClick() {
            this.$emit("updateDisplayEditCardDialog", false)
        },

        onDrag(type) {
            this.isDragOver = type === "over";
        },

        onDropzoneEnter(e){ 
            e.preventDefault()
        },

        async onDrop(event) {
            this.isDragOver = false;
            const files = event.dataTransfer.files;

            this.isUploading = true
            for (let i = 0; i < files.length; i++) {
                let form = new FormData()
                form.append('file', files[i])
                await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: form})
            }
            await this.updateBackAndFrontData()
            this.isUploading = false
        },

        onCoverImageClick(e, url) {
            this.isShowCover = !this.isShowCover
            this.PopupImageUrl = url
        },

        // coverImageBackgroundURL: function(url) {
        //     // const Ext = url.split('.').pop()
        //     if(url) {
        //         return `url(${url})`
        //     }
        //
        //     return ""
        // },

        previewImageBackgroundURL(Attachment) {
            if(this.$constants.imageExtList.includes(Attachment.Ext)) {
                return `url(${Attachment.URL})`
            } else {
                return ""
            }

        },
        previewImage(Attachment) {
            if(this.$constants.imageExtList.includes(Attachment.Ext)) {
                return ""
            } else {
                return ""
            }
        },
        onAttachmentThumbDetailClick(e, Attachment) {
            if(this.$constants.imageExtList.includes(Attachment.Ext)) {
                this.isShowCover = !this.isShowCover
                this.PopupImageUrl = Attachment.URL
            }
        },
        async onAttachmentDownloadIconlick(e, Attachment, URL, OriginalFileName, _id, contentType) {
            if(!Attachment.disabled) {
                Attachment.disabled = true
                const res = await http.get(URL, 
                    { responseType: 'blob' }
                )

                const alink = document.createElement('a');
                alink.target = "_blank"; 
                alink.download = OriginalFileName; 
                let binData = [res]
                let url = window.URL.createObjectURL(new Blob(binData, {type: contentType}))
                alink.href = url;
                if (window.navigator.msSaveBlob) {
                    var xhr = new XMLHttpRequest();
                    xhr.open("GET", alink.href, true);
                    xhr.responseType = "blob";
                    xhr.onload = function (e) {
                        var blob = xhr.response;
                        window.navigator.msSaveBlob(blob, fileName);
                    }
                    xhr.send();
                } else {
                    const e = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
                    alink.dispatchEvent(e);
                }
                alink.remove();

                Attachment.disabled = false
            }
        },

        async onAttachmentDeleteClick(event, AttachmentId) {
            await this.$store.dispatch("activeBoardCard/deleteAttachment", {BoardCardId: this.BoardCardId, AttachmentId: AttachmentId })
            this.$refs["deleteFileCommand_"+AttachmentId][0].hide(event)

            this.updateBackAndFrontData()
        },

        async checkCardAttachmentVersion(AttachmentId, CardAttachmentVersionText) {
            const self = this
            return new Promise(async function(resolve, reject) {
                const res = await self.$store.dispatch("activeBoardCard/checkCardAttachmentVersion", {AttachmentId: AttachmentId, CardAttachmentVersionText: CardAttachmentVersionText})
                if(res.Success) {
                    resolve(true)
                } else {
                    self.$confirm.require({
                        message: self.$i18n.tc("top.anotherUserModified", {USEREMAIL: res.LastUpdatedWorkUserEmail}),
                        accept: function() {
                            resolve(true)
                        },
                        reject: () => {
                            resolve(false)
                        },
                    })
                }
            })

        },

        onAttachmentCommentTextareaFocus(event, Attachment) {
            Attachment.OldComment = Attachment.Comment
        },

        async onAttachmentCommentTextareaBlur(event, Attachment) {
            if (!(await this.checkCardAttachmentVersion(Attachment._id, Attachment.CardAttachmentVersionText))) {
                Attachment.Comment = Attachment.OldComment
                Attachment.CommentEditting = false
                Attachment.activeCommentInPlace = false
            } else {
                const AttachmentId = Attachment._id
                const Comment = Attachment.Comment
                Attachment.CommentEditting = false
                Attachment.activeCommentInPlace = false
                const res = await this.$store.dispatch("activeBoardCard/commentAttachment", {BoardCardId: this.BoardCardId, AttachmentId: AttachmentId, Comment: Comment})

                this.updateBackAndFrontData()
            }
        },

        async openCommentInplace(Attachment, AttachmentId) {
            Attachment.CommentEditting = true
            await (Attachment.activeCommentInPlace = true)
            const self = this;
            this.$nextTick(function() {
                const ref = self.$refs["textarea_comment_ref_" + AttachmentId]
                console.log(ref)
                if(ref && ref.length) {
                    ref[0].$el.select()
                }
                
            })
        },

        async onChangeFileNameAttachmentCancelClick(event, Attachment) {
            this.$refs["changeFileNameCommand_"+Attachment._id][0].toggle(event)
        },

        async onChangeFileNameAttachmentClick(event, Attachment) {
            const AttachmentId = Attachment._id
            // const pattern = /[\\\/:\*\?\"<>\|]/;
            const pattern = /[\\\/~"#%&*:<>?{}.\|]/;
            if (pattern.test(Attachment.EdittingFileName + Attachment.Ext)) {
                this.invalidViewMode.value = true
                this.invalidViewMode.text = this.$i18n.t("EditCardDialog.invalidFileNameLetter", {INVALID: this.$constants.invalidFileNameLetter})
                return
            }

            if((Attachment.EdittingFileName.trim() + "." + Attachment.Ext).length >= 255)  {
                this.invalidViewMode.value = true
                this.invalidViewMode.text = this.$i18n.tc("EditCardDialog.fileLength")
                return
            }
            Attachment.FileName = Attachment.EdittingFileName.trim() + "." + Attachment.Ext
            await this.$store.dispatch("activeBoardCard/changeFileNameAttachment", {BoardCardId: this.BoardCardId, AttachmentId: AttachmentId, FileName: Attachment.EdittingFileName.trim()})
            this.invalidViewMode.value = false
            this.invalidViewMode.text = ""
            this.$refs["changeFileNameCommand_"+Attachment._id][0].toggle(event)
            if(Attachment.IsCoveredImage)
                this.updateBackAndFrontData()
        },

        onCloseFileNameAttachmentClick(event, Attachment) {
            this.$refs["changeFileNameCommand_"+Attachment._id][0].hide()
        },

        async onAttachmentChangeFilenameCommandClick(event, Attachment) {
            this.invalidViewMode.value = false
            this.invalidViewMode.text = this.$i18n.t("EditCardDialog.invalidFileNameLetter", {INVALID: this.$constants.invalidFileNameLetter})
            const formerFileName = Attachment.FileName.split(".")
            if(formerFileName.length > 1)
                formerFileName.pop()
            Attachment.EdittingFileName = formerFileName.join("")
            this.$refs["deleteFileCommand_"+Attachment._id][0].hide()
            this.$refs["changeFileNameCommand_"+Attachment._id][0].toggle(event)
        },

        async onAttachmentCommentCommandClick(event, Attachment) {
            this.openCommentInplace(Attachment, Attachment._id)
        },

        async onAttachmentDeleteCommandClick(event, AttachmentId) {
            this.$refs["changeFileNameCommand_"+AttachmentId][0].hide()
            this.$refs["deleteFileCommand_"+AttachmentId][0].toggle(event)
        },

        async onAttachmentMakeCoverClick(event, AttachmentId) {
            await this.$store.dispatch("activeBoardCard/makeCoverAttachment", {BoardCardId: this.BoardCardId, AttachmentId: AttachmentId })
            await this.updateBackAndFrontData()
        },

        async onAttachmentRemoveCoverClick(event, AttachmentId) {
            await this.$store.dispatch("activeBoardCard/removeCoverAttachment", {BoardCardId: this.BoardCardId, AttachmentId: AttachmentId })
            await this.updateBackAndFrontData()

        },

        async updateBackAndFrontData() {
            const res = await this.$store.dispatch("activeBoardCard/getCardInfoById", this.BoardCardId)
            if(res.Success) {
                const id = this.$store.getters["activeBoardCard/getId"]
                if(this.BoardCardId == id) {
                    await this.$store.dispatch('activeBoardCard/setCardInfo', res)
                }
            }
            await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })

        },

        async onAddAttachmentButtonClick() {
            document.getElementById('addCardFileButton').click()
        },

        changeUploadFile: async function(e) {
            if(e.target.files.length) {
                const file = e.target.files[0]
                let form = new FormData()
                form.append('file', file)
                this.isUploading = true
                await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: form})
                await this.updateBackAndFrontData()
                this.isUploading = false
            }
        },

        onPopTagIconClick: async function(e) {
            this.$refs.popTagPanelRef.toggle(e)
        },

        closeTagPopPanel: function() {
            this.$refs.popTagPanelRef.hide()
        },

        onPopMoveIconClick: async function(e) {
            if(!this.IsPinned)
                this.$refs.popMovePanelRef.toggle(e)
        },

        onDeleteCardClick: async function(e) {
            const self = this
            this.$confirm.require({
                header: self.$i18n.tc("top.deleteCardForever"),
                message: self.$i18n.tc("top.deleteCardForeverInfo"),
                acceptLabel: self.$i18n.tc("utils.delete"),
                accept: async function() {
                    const res = await self.$store.dispatch("activeBoardCard/trashBoardCardForever", self.BoardCardId)
                    if(res.Success) {
                        self.$toast.add({severity:'success', summary: self.$i18n.tc("utils.deleteSuccess"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        await self.$store.dispatch("trello/getBoardListCards", {BoardListId: self.BoardListId, BoardId: self.BoardId })
                        self.$emit("updateDisplayEditCardDialog", false)
                    } else {
                        self.$toast.add({severity:'error', summary: self.$i18n.tc("utils.failed"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                    }
                },
                reject: () => {
                },
            })

        },

        getImageDataFromClipboard: async function(event) {
            const result = await helper.getDataListFromClipboard(event)
            if(!result.Success) {
                return 
            }
            if (result.DataType == "file") {
                const fileList = result.Data
                if (fileList.length) {
                    event.preventDefault()
                } else {
                    return 
                }
                this.isUploading = true

                for (let i = 0; i < fileList.length; i++) {
                    const  file = fileList[i]
                    let form = new FormData()
                    form.append('file', file)
                    await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: form})
                }
                await this.$store.dispatch("activeBoardCard/loadAttachments", {})
                this.isUploading = false
            }
        },
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
.isBorderNone {
    border: none;
}
.titleDisplay {
    word-break: break-all; 
}
.titleDisplay-empty:hover {
    /* background-color: rgb(219 221 227); */

}
.attachmentThumb {
    border-radius: 3px;
    margin: 0 0 0px;
    /* margin: 0 0 8px; */
    min-height: 80px;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.attachmentThumb:hover .attachmentThumbDetail{ 
    background-color: #091e420a;
}
.attachmentThumbDetail {
    box-sizing: border-box;
    cursor: pointer;
    margin: 0;
    min-height: 80px;
    padding: 8px 8px 8px 128px;
    z-index: 0;
}
.attachmentThumbDetailFileName {
    word-wrap: break-word;
    font-weight: 700;
    color: #172b4d;
}
.attachmentThumbDetailFileDate{
    color: #5e6c84;
}
.attachmentThumbDetailFileCommand {
    color: #5e6c84;
    text-decoration: underline;
}
.attachmentThumbDetailFileCommand:hover {
    color: #172b4d;
    font-weight: 500;
}
.attachmentThumbPreview {
    background-color: #091e420a;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 3px;
    height: 80px;
    left: 0;
    margin-top: -40px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 50%;
    width: 112px;
    z-index: 1;
    cursor: pointer;
}
.attachmentThumbPreviewExt {
    color: #5e6c84;
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 80px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-all;
    text-decoration: none;
    width: 100%;
}
.attachmentThumbPreviewExtHeader {
    color: #5e6c84;
    margin-top: 40px;
    padding-right: 1rem;
    padding-left: 1rem;
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 20px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-all;
    text-decoration: none;
    width: 100%;
}
.coverImage {
    min-height: 120px;
    max-height: 120px;
    object-fit: contain;
    background-size: contain;
    background-origin: content-box;
    padding: 0px;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
}
.downloadAttachmentIcon {
    color: #172b4d;
}
.downloadAttachmentIcon:hover {
    color: #091e42;
    font-weight: 600;
}

.createNewButton {
    /* width: 100%; */
    border-color: #ebecf0;
    background-color: #ebecf0;
    border-radius: 3px;
    color: #5e6c84;
    /* display: block; */
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 0.5rem;
}
.createNewButton:hover {
    color: #172b4d;
    border-color: #091e4214;
    background-color: #091e4214;
}
.cardFromDetailInfo {
    color: #5e6c84;
    text-decoration: underline;
}
.droppingZone {
    bottom: 0;
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    left: 0;
    line-height: 30px;
    opacity: 1;
    padding: 200px 50px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: auto;
    background: #ffffffb3;
    margin-top: 0px;
}
</style>

