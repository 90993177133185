<template>
    <div
        v-bind:style="{'top': quickEditCardElement.quickCardTop+'px', 'left': quickEditCardElement.quickCardLeft+'px', 'z-index': quickEditCardElement.quickCardZIndex}"
        style="position: absolute; user-select: none"
        v-if="blockedDisplay"
    >
    <div>
        <div style="position: relative;">
            <Card 
                v-if="blockedDisplay"
                class="p-ripple cursor_pointer text-left editCardDialogSummary list-group-item IsDirectEditCard no_margin"
                v-bind:style="{'width': quickEditCardElement.quickCardWidth+'px'}"
                @dragover.prevent="onDrag('over')"  @drop.prevent="onDrop($event)"
            >
            <template #header>
                <template v-if="IsPinned" >
                    <div class=" text-right" style="padding-right: 6px;">
                        <i class="" style="fontSize: 0.6rem" >
                            <ThumbtackIcon class=" fa-rotate-45" />
                        </i>
                    </div>
                </template>
                <template v-if="CoverImageUrl">
                    <template v-if="isUploading">
                        <div class="p-mt-1 p-pr-4 p-pl-4" >
                            <Skeleton class="coverImage" borderRadius="8px"></Skeleton>
                        </div>
                    </template>
                    <template v-else-if="$constants.imageExtSet.has(CoverFileNameExt)">
                        <div v-if="isImageLoading" class="p-mt-1 p-pr-4 p-pl-4" >
                            <Skeleton class="coverImage" borderRadius="8px"></Skeleton>
                        </div>
                        <div class="cursor_pointer coverImage" :class="{'display_none': isImageLoading}" >
                            <img class="coverImage" :src="CoverImageUrl" @load="loadCoverImage" />
                        </div>
                    </template>
                    <template v-else>
                        <a class="coverImage" >
                            <span class="attachmentThumbPreviewExtHeader">{{CoverFileName}}</span>
                        </a>
                    </template>
                </template>
            </template>
                <template #content>
                    <TagLineField :BoardId="BoardId" :TagIdList="TagIdList ? TagIdList : []" IsSlim=true />
                    <Textarea 
                          type="text"
                          style=" width: 100%; height: 100%; min-height: 90px;padding: 5px;"
                          class="textfield IsDirectEditCardTextArea" 
                          v-model="Title"
                          ref="quickEditCardElementTextareaRef"
                          :class="{'p-invalid': invalidTextArea.value}"
                          @keydown.enter.meta.exact="enterEditCard"
                          @keydown.enter.ctrl.exact="enterEditCard"
                          @keydown.ctrl.v.exact="quickEditCardPaste"
                          @paste="quickEditCardPaste"
                          autoResize
                        />
                </template>
                <template #footer>
                    <div style="width: 100%; text-align: left">
                    <span style="text-align: left" >{{No}}</span>
                    </div>
                </template>
            </Card>
            <div v-if="isDragOver" class="droppingZone" @dragover.prevent="onDropzoneEnter" @dragleave.prevent="onDrag('leave')"  @drop.prevent="onDrop($event)"> 
                <div class="droppingZoneContent">drop</div>
            </div>
            </div>
            <div class="p-grid no_padding no_margin">
                <Button v-if="blockedDisplay" class="p-mt-2" @click="quickEditSave($event, quickEditCardElement)" style="line-height: 12px;"> 
                    <div v-if="!saveQuickButtonLoading">
                        <div style="display: contents; text-transform:capitalize;">{{$t("utils.save")}} </div> <br /> <div style="font-size: 10px;display: contents;" >(Ctrl+Enter)</div>
                    </div>
                    <i class="pi pi-spinner pi-spin" v-else />
                </Button>
                <div class="p-col" />
                <Button v-if="blockedDisplay && !quickEditCardElement.IsNew" class="p-mt-2 p-button-secondary" @click="onOpenEditCardClick($event, quickEditCardElement)" > {{$t('top.quickCard.open')}} </Button>
                
            </div>
        </div>
        <div 
          style="position: absolute; top: 0px;"
            v-bind:style="{'left': quickEditCardElement.subItemPosition == 'left' ? quickEditCardElement.quickCardWidth+'px' : 'none', 'right': quickEditCardElement.subItemPosition == 'right' ? quickEditCardElement.quickCardWidth+'px' : 'none'}"
             v-if="!quickEditCardElement.IsNew"
            >
            <div class="btn-group list-group-item p-grid" role="group" style=" position: relative; margin: 0rem 0.5rem  0rem 0.5rem; min-width: 100px;">
            <Button v-if="IsPinned" class="p-col-12 p-mb-1 p-button-secondary" @click="setPinnedBoardCard">{{$t('top.quickCard.unPinButton')}}</Button>
            <Button v-else class="p-col-12 p-mb-1 p-button-secondary" @click="setPinnedBoardCard">{{$t('top.quickCard.pinButton')}}</Button>

            <Button class="p-col-12 p-mb-1 p-button-secondary" @click="onLabelPopClick">{{$t('top.quickCard.labelButton')}}</Button>
 
            <OverlayPanel ref="popTagPanelRef" style="min-width: 300px;" :dismissable="true"  >
                <TagPopPanel :BoardId="BoardId" :BoardCardId="BoardCardId" @closeTagPopPanel="closeTagPopPanel" />
            </OverlayPanel>

            <Button class="p-col-12 p-mb-1 p-button-secondary" @click="onMovelPopClick" :disabled="IsPinned">{{$t('top.quickCard.moveButton')}}</Button>
            <OverlayPanel ref="popMovePanelRef" style="width: 300px; min-width: 300px;" >
                <CardMovePopPanel :BoardId="BoardId" :BoardListId="BoardListId" :BoardCardId="BoardCardId" @updateCardNo="updateCardNo" />
            </OverlayPanel>

            <Button class="p-col-12 p-mb-1 p-button-secondary" @click="onDeleteButtonClick">{{$t('top.quickCard.deleteButton')}}</Button>
            </div>
        </div>
    </div>

</template>

<script>
import Button from 'primevue/button';
import TagLineField from "@/components/board/TagLineField"
import TagPopPanel from "@/components/board/TagPopPanel"
import CardMovePopPanel from "@/components/board/CardMovePopPanel"
import Card from 'primevue/card';
import Textarea from 'primevue/textarea';
import ThumbtackIcon from "@/components/Icons/thumbtackIcon"
import OverlayPanel from 'primevue/overlaypanel';
import Skeleton from 'primevue/skeleton';
import helper from "@/mixins/helper";



export default {
    name: 'QuickEditCard',
    props: ["blockedDisplay", "quickEditCardElement", "BoardId", "BoardListId", "BoardCardId", "saveQuickButtonLoading"],
    emits: ["quickEditSave", "openEditCard", "updateDisplayEditCardDialog"],
    components: {
        Button,
        TagLineField,
        TagPopPanel, 
        CardMovePopPanel,
        Card,
        Textarea, 
        ThumbtackIcon,
        OverlayPanel,
        Skeleton,
    },
    data: function() {
        return {
            quickTagPop: false,
            quickMovePop: false,
            isUploading: false,
            attachment4New: null,
            isImageLoading: false,
            isDragOver: false,
            saving: false,
            invalidTextArea: {
                value: false
            },
        }
    },
    created() {
    },
    watch: {
        blockedDisplay: function(value) {
            if(value) {
                this.attachment4New = null
                this.isImageLoading = true
                this.invalidTextArea = {
                    value: false
                }
                const self = this
                this.$nextTick(function() {
                    self.$refs.quickEditCardElementTextareaRef.$el.select()
                })
            }
        },
    },
    computed: {
        coverImageBackgroundURL: {
            get: function() {
                return `url(${this.CoverImageUrl})`
            },
        },
        Title: {
            get: function() {
                return this.$store.getters["activeBoardCard/getTitle"]
            },
            set: function(value) {
                this.$store.commit("activeBoardCard/setTitle", value)
            }
        },
        Content: {
            get: function() {
                return this.$store.getters["activeBoardCard/getContent"]
            },
            set: function(value) {
                this.$store.commit("activeBoardCard/setContent", value)
            }
        },
        CoverFileNameExt: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverFileNameExt"]
            },
        },
        CoverFileName: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverFileName"]
            },
        },
        CoverImageUrl: {
            get: function() {
                return this.$store.getters["activeBoardCard/getCoverImageUrl"]
            },
        },
        UpdatedDate: {
            get: function() {
                return this.$store.getters["activeBoardCard/getUpdatedDate"]
            },
        },
        IsPinned: {
            get: function() {
                return this.$store.getters["activeBoardCard/getPinned"]
            },
        },
        AttachmentList: {
            get: function() {
                return this.$store.getters["activeBoardCard/getAttachmentList"]
            }
        },
        No: {
            get: function() {
                return this.$store.getters["activeBoardCard/getNo"]
            }
        },
        BelongToTitle: {
            get: function() {
                return this.$store.getters["activeBoardCard/getBelongToTitle"]
            }
        },
        ListName: {
            get: function() {
                return this.$store.getters["activeBoardCard/getListName"]
            }
        },
        TagIdList: {
            get: function() {
                return this.$store.getters["activeBoardCard/getTagIdList"];
            }
        },
    },
    methods: {
        loadCoverImage() {
            this.isImageLoading = false
        },
        async quickEditSave(event, element) {
            if(this.saving) {
                return 
            }

            if(!this.checkInvalidTitle()) {
                if (!element.IsNew) {
                    if(!(await this.checkCardVersion()))  {
                        this.$store.commit("activeBoardCard/setTitle", this.$store.getters["activeBoardCard/getOriginalTitle"])
                        return
                    }
                }
                this.switchSaving(true)
                element.IsDirectEdit = true
                element.editDone = false
                element.Title = this.Title
                element.BoardId = this.BoardId
                element.BoardListId = this.BoardListId
                element.IsNew = this.quickEditCardElement.IsNew
                if(element.IsNew) {
                    element.attachment4New = this.attachment4New
                }

                const params = {event, element}

                this.$emit('quickEditSave', params)
            }
        },

        async checkCardVersion() {
            const self = this
            return new Promise(async function(resolve, reject) {
                const res = await self.$store.dispatch("activeBoardCard/checkCardVersion")
                if(res.Success) {
                    resolve(true)
                } else {
                    self.$confirm.require({
                        message: self.$i18n.tc("top.anotherUserModified", {USEREMAIL: res.LastUpdatedWorkUserEmail}),
                        accept: function() {
                            resolve(true)
                        },
                        reject: () => {
                            resolve(false)
                        },
                    })
                }
            })

        },

        switchSaving(data) {
            this.saving = data
        },

        checkInvalidTitle() {
            const invalid = this.Title.length >= this.$constants.maxCardTitleLength
            if (invalid) {
                this.invalidTextArea.value = true
                this.$toast.add({severity: 'error', summary: this.$i18n.t("EditCardDialog.invalidTitleLength"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.invalidTextArea.value = false
            }
            return invalid
        },

        onOpenEditCardClick(event, element) {
            element.IsDirectEdit = true
            element.editDone = false
            element.Title = this.Title
            element.BoardId = this.BoardId
            element.BoardListId = this.BoardListId

            const params = {event, element}
            this.$emit('openEditCard', params)
        },

        setPinnedBoardCard: async function(e) {
            const nextPinned = !this.IsPinned
            await this.$store.dispatch("activeBoardCard/setPinnedCard", {BoardCardId: this.BoardCardId, IsPinned: nextPinned })
            this.$store.commit("activeBoardCard/setIsPinned", nextPinned)
            this.quickEditCardElement.IsPinned = nextPinned
            await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })

            this.quickEditSave({}, this.quickEditCardElement)
        },

        onLabelPopClick: async function(e) {
            this.$refs.popTagPanelRef.toggle(e)
        },

        closeTagPopPanel: function() {
            this.$refs.popTagPanelRef.hide()
        },

        onMovelPopClick: function(e) {
            this.$refs.popMovePanelRef.toggle(e)
        },

        updateCardNo: function(e) {
            this.$refs.popMovePanelRef.toggle(e)
            this.quickEditSave({}, this.quickEditCardElement)
        },

        onDeleteButtonClick: function(e) {
            const self = this
            this.$confirm.require({
                header: self.$i18n.tc("top.deleteCardForever"),
                message: self.$i18n.tc("top.deleteCardForeverInfo"),
                acceptLabel: self.$i18n.tc("utils.delete"),
                accept: async function() {
                    self.isUploading = true
                    const res = await self.$store.dispatch("activeBoardCard/trashBoardCardForever", self.BoardCardId)
                    if(res.Success) {
                        self.$toast.add({severity:'success', summary: self.$i18n.tc("utils.deleteSuccess"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        await self.$store.dispatch("trello/getBoardListCards", {BoardListId: self.BoardListId, BoardId: self.BoardId })
                        self.$emit("updateDisplayEditCardDialog", false)
                    } else {
                        self.$toast.add({severity:'error', summary: self.$i18n.tc("utils.failed"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                    }
                    self.isUploading = false
                },
                reject: () => {
                },
            })
        },

        enterEditCard: function(e) {
            if(e.ctrlKey || e.metaKey) {
                this.quickEditSave(e, this.quickEditCardElement)
            }
        },

        quickEditCardPaste: async function(event) {
            const result = await helper.getDataListFromClipboard(event)
            if(!result.Success) {
                return 
            }
            if (result.DataType == "file") {
                const fileList = result.Data
                if (fileList.length) {
                    event.preventDefault()
                } else {
                    return 
                }
                await this.uploadImages(fileList)
            }
        },

        uploadImages: async function(fileList) {
            if(this.quickEditCardElement.IsNew) {
                this.isUploading = true
                if(!this.attachment4New) {
                    this.attachment4New = new FormData()
                }
                for (let i = 0; i < fileList.length; i++) {
                    const file = fileList[i]
                    this.attachment4New.append('file', file)
                    // if(this.$constants.imageExtList.includes(file.name.split('.').pop().toLowerCase())) {
                        const url = URL.createObjectURL(file)
                        this.$store.commit("activeBoardCard/setCoverImageUrl", url)
                        this.$store.commit("activeBoardCard/setCoverFileNameExt", file.name.split('.').pop().toLowerCase())
                        this.$store.commit("activeBoardCard/setCoverFileName", file.name)
                    //     
                    // }
                    if(!this.Title) {
                        if (i == (fileList.length - 1)) {
                            this.$store.commit("activeBoardCard/setTitle", file.name)
                        }
                    }
                }
                this.isUploading = false
            } else {
                this.isUploading = true
                for (let i = 0; i < fileList.length; i++) {
                    const  file = fileList[i]
                    let form = new FormData()
                    form.append('file', file)
                    await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: form})
                    if(!this.Title) {
                        if (i == (fileList.length - 1)) {
                            this.$store.commit("activeBoardCard/setTitle", file.name)
                        }
                    }
                }
                await this.$store.dispatch("activeBoardCard/loadAttachments", {})
                this.isUploading = false
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.BoardListId, BoardId: this.BoardId })
            }
        },

        onDrag(type) {
            this.isDragOver = type === "over";
        },

        onDropzoneEnter(e){ 
            e.preventDefault()
        },

        async onDrop(event) {
            this.isDragOver = false;
            const files = event.dataTransfer.files;
            // if (files.length !== 1) {
            //     return;
            // }
            await this.uploadImages(files)
        },
    },
}
</script>

<style scoped>
.coverImage {
    min-height: 120px;
    max-height: 120px;
    object-fit: contain;
    background-size: contain;
    background-origin: content-box;
    padding: 0px;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
}
.attachmentThumbPreviewExtHeader {
    color: #5e6c84;
    padding-right: 10px;
    padding-left: 10px;
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 20px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-all;
    text-decoration: none;
    width: 100%;
}
.droppingZone {
    bottom: 0;
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    left: 0;
    line-height: 30px;
    opacity: 1;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: auto;
    background: #ffffffb3;
    margin-top: 0px;
}
.droppingZoneContent {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-right: -50%;
}
</style>
