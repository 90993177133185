<template>
    <div class="p-grid">
        <div class="text-center p-col-12" style="color: #172b4d; font-size: 20px;"> {{$t('EditCardDialog.cardMovePopPanel.title')}}</div>
        <div class=" p-col-12" style=""> {{$t('EditCardDialog.cardMovePopPanel.info')}}</div>
        <div class=" p-col-12">
            <Dropdown class="width-100 text-ellipsis-dropbox" v-model="ListDataId" :options="BoardList" optionLabel="label" optionValue="_id"  :placeholder="$t('EditCardDialog.cardMovePopPanel.list')" @change="onBoardListChange" />
        </div>
        <div class="p-col-4" />
        <div class="p-col-8 " style="" >
            <Dropdown v-model="No"  class="width-100 text-ellipsis-dropbox" :options="NoList" optionDisabled="disabled" optionLabel="label" optionValue="value" :placeholder="$t('EditCardDialog.cardMovePopPanel.position')" @change="onNoListChange" />
        </div>
        <div class="p-col-4" />
        <div class="p-col-8" style="text-align: end" >
            <Button @click="onMoveCardButtonClick">{{$t('EditCardDialog.cardMovePopPanel.updateButton')}}</Button>
        </div>
    </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';


export default {
    name: 'CardMovePopPanel',
    props: ["BoardId", "BoardListId","BoardCardId"],
    emits: ["updateCardNo"],
    components: {
        Dropdown,
        Button,
    },
    data: function() {
        return {
            selectedBoardDataId: this.BoardListId,
            selectedNo: "",
        }
    },
    created() {
    },
    computed: {
        BoardList: {
            get() {
                const BoardDataList = this.$store.getters["trello/getAllBoardList"]
                return BoardDataList
            }
        },
        ListDataId: {
            get() {
                if(this.selectedBoardDataId) {
                    return this.selectedBoardDataId
                }

                return this.BoardListId
            },
            set() {

            }
        },
        NoList: {
            get() {
                const AllBoards = this.$store.getters["trello/getAllBoards"]
                const ListId = this.selectedBoardDataId ? this.selectedBoardDataId : this.BoardListId
                const Board = AllBoards.find(x => x._id == this.BoardId)
                let List = []
                for(let i = 0; i < AllBoards.length; i++) {
                    const Board = AllBoards[i]
                    const index = Board.Lists.findIndex(x => x._id == ListId)
                    if(index != -1) {
                        List = Board.Lists[index]
                        break
                    }

                }
                // if(!List.length) return 0;

                const NoList = []

                for (let i = 0; i < List.Cards.length; i++) {
                    const Card = List.Cards[i]
                    if(Card.IsPinned) {
                        NoList.push({
                            label: Card.No + this.$i18n.tc('EditCardDialog.cardMovePopPanel.isPined'),
                            value: Card.No,
                            disabled: true,
                        })
                    }
                    else if(Card._id == this.BoardCardId) {
                        NoList.push({
                            label: Card.No + this.$i18n.tc('EditCardDialog.cardMovePopPanel.isCurrentCardNo'),
                            value: Card.No,
                            disabled: true,
                        })
                    } else {
                        NoList.push({
                            label: Card.No,
                            value: Card.No,
                        })
                    }
                }
                if(!NoList.length) {
                    NoList.push({
                        label: 1,
                        value: 1,
                    })
                }

                return NoList
            },
        },
        No: {
            get() {
                if(this.selectedNo) {
                    return this.selectedNo
                }
                return this.$store.getters["activeBoardCard/getNo"]
            },
            set() {

            }
        }
    },
    methods: {
        onBoardListChange(e) {
            const value = e.value
            this.selectedBoardDataId = value
        },

        onNoListChange(e) {
            const value = e.value
            this.selectedNo = value

        },

        async onMoveCardButtonClick(e) {
            const res = await this.$store.dispatch("trello/moveCardInPanel", {BoardListId: this.ListDataId, No: this.No, CardId: this.BoardCardId})
            if(res.Success) {
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.ListDataId, BoardId: this.BoardId})
                await this.$store.dispatch("activeBoardCard/getCardInfoById", this.BoardCardId)
                if(this.ListDataId) {
                    await this.$store.dispatch("trello/getBoardListCards", {BoardListId: this.ListDataId, BoardId: this.BoardId})
                }
                this.$toast.add({severity:'success', summary: this.$i18n.tc('EditCardDialog.cardMovePopPanel.moved'), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$emit('updateCardNo')
            } else {
                this.$toast.add({severity:'error', summary: "Error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        }
    },
}
</script>

<style scoped>
</style>
