<template>
    <div class="p-col-12 no_padding text-left" style="">
        <template v-for="BoardTag, index in BoardTagList" :key="index+ '_tagLineField'">
            <Tag 
                  v-if="TagIdList && TagIdList.includes(BoardTag._id)" 
                  :value="BoardTag.Label ? BoardTag.Label : ' '"
                  class="p-mr-1 p-mb-1 tagLineTag cursor_pointer"
                  :class="{tagLineTagSlim: IsSlim, tagLineTagPoint: !IsSlim }"
                  style="min-width: 40px;"
                  v-bind:style="{'background': '#' + BoardTag.Color}" 
                  @click="onPopTagIconClick($event, index)"
            />
            <OverlayPanel :ref="'popTagPanelRef_'+index" style="min-width: 300px;" >
                <TagPopPanel :BoardId="BoardId" :BoardCardId="BoardCardId" @closeTagPopPanel="closeTagPopPanel($event, index)" />
            </OverlayPanel>
        </template>
    </div>
</template>

<script>


import Tag from 'primevue/tag';
import OverlayPanel from 'primevue/overlaypanel';
import TagPopPanel from "@/components/board/TagPopPanel"

export default {
    name: 'TagLineField',
    props: ["BoardId", "BoardCardId", "TagIdList", "IsSlim"],
    emits: [],
    components: {
        Tag,
        OverlayPanel,
        TagPopPanel,
    },
    data: function() {
        return {
        }
    },
    created() {
    },
    computed: {
        // TagIdList: {
        //     get() {
        //         return this.$store.getters["activeBoardCard/getTagIdList"];
        //     }
        // },
        BoardTagList: {
            get() {
                const AllBoardTagList = this.$store.getters["trello/getAllBoardTagList"];
                return AllBoardTagList.filter(x => x.BoardId == this.BoardId);
            }
        },
    },
    methods: {
        onPopTagIconClick: async function(e, index) {
            if(this.BoardCardId) {
                this.$refs["popTagPanelRef_"+index][0].toggle(e)
            }
        },

        closeTagPopPanel: function(e, index) {
            this.$refs["popTagPanelRef_"+index][0].hide()
        }
    },
}
</script>

<style scoped>
.tagLineTagPoint:hover {
    opacity: 0.8;
}
</style>
