<template>
    <Inplace class="handle boardListTitleInplace " :active="activeInPlace" @open="openInplace" style="padding: 0px;">
        <template #display>
            <h3 class="titleDisplay" style="margin: 0px; min-height: 20px;" v-if="Title">{{Title}}</h3>
            <h3 class="titleDisplay titleDisplay-empty" style="min-height: 20px; padding: 0px; margin: 0px;" v-else>{{Title}}</h3>
        </template>
        <template #content >
            <Textarea 
                style="width: 100%;" 
                v-model="Title" 
                :autoResize="true" 
                rows="2" 
                @blur="blurEditContentTextarea($event)" 
                ref="textarea_ref"
                @keydown.enter="enterEditContentTextarea"
                @compositionstart="composing=true"
                @compositionend="composing=false"
                autoFocus 
                />
        </template >
    </Inplace>
</template>


<script>
import Inplace from 'primevue/inplace';
import Textarea from 'primevue/textarea';


export default {
    name: 'TrelloTitleInplace',
    props: ['BoardListId', 'Title'],
    components: {
        Inplace,
        Textarea
    },
    data: function() {
        return {
            activeInPlace: false,
            composing: false,
        }
    },


    created() {
    },
    computed: {
    },
    methods: {
        enterEditContentTextarea() {
            if(!this.composing)
                this.$refs.textarea_ref.$el.blur()
        },
        async blurEditContentTextarea(e) {
            const self = this;
            this.activeInPlace = false
            await self.$store.dispatch("trello/setBoardListTitle", {value: e.target.value, BoardListId: this.BoardListId})
        },

        openInplace() {
            this.activeInPlace = true
            const self = this;
            this.$nextTick(function() {
                self.$refs.textarea_ref.$el.focus()
            })
        },
    },
}
</script>

<style scoped>
.titleDisplay {
    word-break: break-all; 
}
.titleDisplay-empty:hover {
    background-color: rgb(219 221 227);

}
</style>
