<template>
    <div class="width-100 text-right">
        <Button icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text" @click="onTagPopClose" style="color: #495057;" />
    </div>
    <TabView :activeIndex="activeIndex" class="tagPopPanelTabView">
        <TabPanel>
        <div class="text-center" style="color: #172b4d; font-size: 20px;"> {{$t('EditCardDialog.tagPopPanel.title')}}</div>
        <div class="p-pt-2 p-pb-4" > {{$t('EditCardDialog.tagPopPanel.info')}}</div>
        <template v-for="BoardTag in BoardTagList" :key="BoardTag._id">
            <div class="p-col-12  text-left p-grid" style="">
                <div class="p-col p-grid no_padding">
                    <div class="p-col-fixed" style="width: 20px;">
                    </div>
                    <div class="p-col p-pt-1 p-pb-1">
                        <Tag
                            v-if="editingTag._id == BoardTag._id"
                            class="width-100 cursor_pointer no_padding"
                            v-bind:style="{'background': '#' + BoardTag.Color}" 
                        >
                            <InputText type="text" v-model="editingTag.Label" style="padding: 0.25rem;" @blur="onUpdateTagBlur" ref="tagLabelInputRef" @keydown.enter.prevent="onEnter" />
                        </Tag>
                        <template v-else>
                        <template v-if="TagIdList.includes(BoardTag._id)">
                            <Tag
                                :value="BoardTag.Label ? BoardTag.Label : ' '" 
                                icon="pi pi-check"
                                class="width-100 cursor_pointer tagPopPanelTag"
                                v-bind:style="{'background': '#' + BoardTag.Color}" 
                                @click="onTagClick($event, BoardTag._id)"
                            />
                        </template>
                        <template v-else>
                            <Tag
                                :value="BoardTag.Label ? BoardTag.Label : ' '" 
                                class="width-100 cursor_pointer tagPopPanelTag"
                                v-bind:style="{'background': '#' + BoardTag.Color}" 
                                @click="onTagClick($event, BoardTag._id)"
                            />
                        </template>
                        </template>
                    </div>
                </div>
                <div class="p-col-fixed no_padding text-right" style="width: 24px;">
                    <i class="pi pi-pencil cursor_pointer" @click="onEditTagIconClick($event, BoardTag)"></i>
                </div>
            </div>
        </template>
        </TabPanel>
        <TabPanel>
            <div class="p-col-12 no_padding text-left p-grid" style="">
                <InputText class="p-col" type="text" v-model="editingTag.Label" autoFocus/>
                <ColorPicker  v-model="editingTag.Color" />
            </div>
            <div class="p-col-12 no_padding p-grid" style="">
                <Button @click="onUpdateTagButtonClick">
                    save
                </Button>

                <Button @click="onCancelButtonClick">
                    cancel
                </Button>
            </div>
        </TabPanel>
    </TabView >
</template>

<script>


import Tag from 'primevue/tag';
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ColorPicker from 'primevue/colorpicker';
import InputText from 'primevue/inputtext';


export default {
    name: 'TagPopPanel',
    props: ["BoardId", "BoardCardId"],
    emits: ["closeTagPopPanel"],
    components: {
        Tag,
        Button,
        TabView,
        TabPanel,
        ColorPicker,
        InputText,
    },
    data: function() {
        return {
            activeIndex: 0,
            editingTag: {},
        }
    },
    created() {
    },
    computed: {
        TagIdList: {
            get() {
                return this.$store.getters["activeBoardCard/getTagIdList"];
            }
        },
        BoardTagList: {
            get() {
                const AllBoardTagList = this.$store.getters["trello/getAllBoardTagList"];
                return AllBoardTagList.filter(x => x.BoardId == this.BoardId);
            }
        },
    },
    methods: {
        onEditTagIconClick(e, BoardTag) {
            const self = this
            this.$nextTick(function() {
                if(self.editingTag._id == BoardTag._id) {
                    self.editingTag = {}
                } else {
                    self.editingTag = BoardTag
                    self.$nextTick(function() {
                        self.$refs.tagLabelInputRef[0].$el.select()
                    })
                }
            })
        },

        onNewTagButtonClick(e) {
            this.editingTag = {
                Label: "",
                Color: "FF0000",
                BoardId: this.BoardId,
            }
        },

        async onUpdateTagBlur() {
            const editingTag = this.editingTag
            this.editingTag = {}
            if(editingTag._id) {
                await this.$store.dispatch("activeBoardCard/updateCardTag", editingTag)
            } else {
                // await this.$store.dispatch("trello/createNewCardTag", this.editingTag)
            }

            // await this.$store.dispatch("trello/getAllBoardTagList")
            await this.$store.dispatch("trello/getBoardTagList", this.BoardId)
        },

        async onUpdateTagButtonClick(e) {
            if(this.editingTag._id) {
                await this.$store.dispatch("activeBoardCard/updateCardTag", this.editingTag)
            } else {
                await this.$store.dispatch("trello/createNewCardTag", this.editingTag)
            }

            this.editingTag = {}
            await this.$store.dispatch("trello/getAllBoardTagList")
        },

        onCancelButtonClick(e) {
            this.editingTag = {}
        },

        async onTagClick(e, TagId) {

            const oldTagIdList = this.$store.getters["activeBoardCard/getTagIdList"]
            const index = oldTagIdList.findIndex(x => x == TagId)
            if (index != -1) {
                oldTagIdList.splice(index, 1)
            } else {
                oldTagIdList.push(TagId)
            }
            this.$store.commit("activeBoardCard/setTagIdList", oldTagIdList)

            await this.$store.dispatch("activeBoardCard/getCardInfoById", this.BoardCardId)
            await this.$store.dispatch("activeBoardCard/selectTagInCard", {tagId: TagId, cardId: this.BoardCardId})
            await this.$store.dispatch("trello/getCardInfoByIdAndUpdateBack", {_id: this.BoardCardId, BoardId: this.BoardId})
        },

        async onEnter(e) {
            this.$refs.tagLabelInputRef[0].$el.blur()
        },

        onTagPopClose: function() {
            this.$emit('closeTagPopPanel', true)
        }
    },
}
</script>

<style scoped>
.createNewButton {
    /* width: 100%; */
    border-color: #ebecf0;
    background-color: #ebecf0;
    border-radius: 3px;
    color: #5e6c84;
    /* display: block; */
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
}
.createNewButton:hover {
    color: #172b4d;
    border-color: #091e4214;
    background-color: #091e4214;
}
</style>
