<template>
    <Dialog :header="Title" v-model:visible="displayEditListCardDialog" class="editCardDialog" style="width: 40vw;" :modal=true :dismissableMask=true >
        <div class="p-grid no_margin">
            <div class="p-col-12 CardDialogContent p-field p-grid no_margin">
                <div class="p-col-12 p-grid no_margin no_padding editTopCardInplace">
                    <div class="p-col-12 p-grid no_margin no_padding">
                        <Textarea 
                         style="width: 100%;" 
                         v-model="ListContent" 
                         :autoResize="true"
                         class="isBorderNone"
                         rows="2" 
                         @focus="focusEditListContentTextarea"
                         ref="textarea_ref" 
                         @blur="blurEditListContentTextarea"
                         autoFocus
                         />
                         <div style="width: 100%; height: 10px;" />
                         <div class="p-col-12 p-grid no_margin no_padding" style="justify-content: flex-end;" v-if="isFocus">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain p-mr-2" @click="onCancelUpdateButtonClick" style="font-size: 0.6rem" />
                            <Button class="createNewCardButton activeCreateNewCardButton" @click="onUpdateListCardButtonClick" >{{$t("utils.save")}}</Button>
                         </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <template #footer> -->
        <!-- </template> -->
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Inplace from 'primevue/inplace';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip';


export default {
    name: 'EditListCardDialog',
    props: ['displayEditListCardDialog', 'BoardListId'],
    emits: ['updateDisplayEditListCardDialog'],
    components: {
        Dialog,
        Button,
        Inplace,
        Textarea,
    },
    data: function() {
        return {
            isFocus: false,
            initListContent: "",
        }
    },
    watch: {
        displayEditListCardDialog: function(value) {
            this.$emit("updateDisplayEditListCardDialog", value)
            if(value) {
                this.initListContent = this.ListContent
            }
        }
    },
    created() {
    },
    computed: {
        Title: {
            get: function() {
                return `${this.$i18n.tc('EditCardDialog.reload')} ${this.$filters.formatDate(this.ListUpdatedDate, null, 'HH:mm:ss')}`
            }
        },
        ListContent: {
            get: function() {
                return this.$store.getters["trello/getListContent"]
            },
            set: function(value) {
                this.$store.commit("trello/setListContent", value)
            }
        },
        ListUpdatedDate: {
            get: function() {
                return this.$store.getters["trello/getListUpdatedDate"]
            },
        },
    },
    methods: {
        async UpsertListCard() {
            const res = await this.$store.dispatch("trello/setListContentInfoById", {listId: this.BoardListId})
            if(res.Success) {
                this.initListContent = this.ListContent
                this.$store.commit("trello/setListUpdatedDate", Date(res.ListUpdatedDate))
            }
        },

        async onUpdateListCardButtonClick() {
            this.isFocus = false
            await this.UpsertListCard()
            this.$emit("updateDisplayEditListCardDialog", false)
        },

        onCancelUpdateButtonClick() {
            this.isFocus = false
            this.$store.commit("trello/setListContent", this.initListContent)
        },

        async focusEditListContentTextarea() {
            this.isFocus = true
        },

        async blurEditListContentTextarea() {
            const self = this
            setTimeout(async function() {
                const isFocusOld = self.isFocus
                self.isFocus = false
                if(isFocusOld && self.initListContent != self.ListContent) {
                    // await self.UpsertListNewCard()
                }
            }, 300)

        },
    },
    directives: {
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
@keyframes p-progress-spinner-color {
    100%,
    0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}
.isBorderNone {
    border: none;
}
</style>


